import IconSchedule from "~/img/icons/schedule.svg";

export default function LastOpportunities(props: { hasAnimation: boolean }) {
  return (
    <>
      <div
        class="last-opportunities"
        classList={{ "has-offer": props.hasAnimation }}
      >
        <i aria-hidden="true" class="cog-icon">
          <IconSchedule />
        </i>
        <span>Dernières opportunités !</span>
      </div>
    </>
  );
}
