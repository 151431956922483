import Visual from "~/components/Cards/Components/Visual";
import { Show } from "solid-js";
import * as helper from "~/utils/helper_program";
import LastOpportunities from "~/components/Cards/Components/LastOpportunities";
import CardOffer from "~/components/Cards/Components/CardOffer";
import CardPush from "~/components/Cards/Components/CardPush";
import Cogehome3DIcon from "~/components/Cards/Components/Cogehome3DIcon";
import Typologies from "~/components/Cards/Components/Typologies";
import { urlRs } from "~/utils/url";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import { isLastOpportunity } from "~/utils/helper_program";

export default function VisualContainer(props: { wrapper: ProgramWrapper }) {
  return (
    <>
      <div class="visual-container">
        <Visual imageDesktop={props.wrapper.program.field_image_desktop!} />
        <Show
          when={
            !helper.isPreview(props.wrapper) &&
            !helper.isOutOfStock(props.wrapper) &&
            isLastOpportunity(props.wrapper)
          }
        >
          <LastOpportunities
            hasAnimation={!!props.wrapper.program?.animation}
          />
        </Show>
        <Show when={props.wrapper.program?.animation}>
          <CardOffer />
        </Show>
        <CardPush pushTop={props.wrapper.program.field_push_top} />
        <Show when={props.wrapper.program.field_is_cogehome3d}>
          <Cogehome3DIcon />
        </Show>

        <Typologies wrapper={props.wrapper} />

        <a
          href={urlRs("programs", props.wrapper.program.path.alias)}
          class="more-link"
        >
          En savoir plus
        </a>
      </div>
    </>
  );
}
