import { For, Match, Show, Switch } from "solid-js";

import type { TermEntityReferenceField } from "~/types/drupal_jsonapi";

import "./NouveauNeufLogos.css";

import PastilleAccess from "~/img/logos/pastille-cogedim-access.svg";
import PastilleInvest from "~/img/logos/pastille-cogedim-invest.svg";
import PastilleSignature from "~/img/logos/pastille-cogedim-signature.svg";
import { urlRs } from "~/utils/url";

export default function NouveauNeufLogos(props: {
  zone: string;
  segmentsMkg: TermEntityReferenceField[];
}) {
  return (
    <>
      <div class="logos-nouveau-neuf" data-test="logos-nn">
        <For each={props.segmentsMkg}>
          {(segmentMkg: TermEntityReferenceField) => (
            <span
              class="logo"
              data-test={`logo-nn-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
            >
              <Show when={props.zone === "program"}>
                <Switch>
                  <Match when={segmentMkg.name === "Cogedim Access"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access/",
                      )}
                      title="Cogedim Access"
                    >
                      <PastilleAccess />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Invest"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-invest/",
                      )}
                      title="Cogedim Invest"
                    >
                      <PastilleInvest />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-signature/",
                      )}
                      title="Cogedim Signature"
                    >
                      <PastilleSignature />
                    </a>
                  </Match>
                </Switch>
              </Show>
              <Show when={props.zone === "card"}>
                <span
                  class={`tag ${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
                >
                  {segmentMkg.name.replace("Cogedim ", "")}
                </span>
              </Show>
            </span>
          )}
        </For>
      </div>
    </>
  );
}
