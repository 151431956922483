export default function Cogehome3DIcon() {
  return (
    <>
      <img
        src="/images/pictos/cogehome3d-picto.svg"
        alt="Cogehome 3D"
        class="cogehome3d-icon"
      />
    </>
  );
}
