import { createMemo, Show } from "solid-js";
import Regulations from "~/components/Cards/Components/Regulations";

import type { ProgramWrapper } from "~/utils/program_wrapper";

export default function Typologies(props: { wrapper: ProgramWrapper }) {
  const regulationCount = createMemo(() => {
    let count = 0;
    if (props.wrapper.program.field_disp_grid_vat_inc_reduced) count++;
    if (props.wrapper.program.field_disp_grid_vat_inc_mastered) count++;
    if (props.wrapper.program.field_disp_grid_bare_ownership) count++;
    if (props.wrapper.program.field_disp_grid_lmnp_vat_ex) count++;
    if (props.wrapper.program.field_disp_grid_lmnp_vat_inc) count++;
    if (props.wrapper.program.field_disp_grid_vat_inc_brs) count++;
    if (props.wrapper.program.field_disp_grid_pinel) count++;
    if (props.wrapper.program.field_disp_grid_pinel_plus) count++;
    if (props.wrapper.program.field_disp_grid_patrimonial) count++;
    return count;
  });

  return (
    <>
      <div class="info-box">
        <Regulations
          wrapper={props.wrapper}
          regulationCount={regulationCount()}
        />
        <Show
          when={props.wrapper.program.field_address_hide}
          fallback={
            <address
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
              classList={{ "more-regulations": regulationCount() >= 3 }}
            >
              <span itemprop="postalCode">
                {props.wrapper.program.field_postal_code}
              </span>{" "}
              <span itemprop="addressLocality">
                {props.wrapper.program.field_city}
              </span>
            </address>
          }
        >
          <Show when={props.wrapper.program.field_address_override}>
            <address
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
              classList={{ "more-regulations": regulationCount() >= 3 }}
            >
              <span itemprop="addressLocality">
                {props.wrapper.program.field_address_override}
              </span>
            </address>
          </Show>
        </Show>
        <h2 classList={{ "more-regulations": regulationCount() >= 3 }}>
          {props.wrapper.program.title}
        </h2>
      </div>
    </>
  );
}
