import { Show } from "solid-js";
import { urlRs } from "~/utils/url";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import IconCall from "~/img/icons/call.svg";
import IconDescription from "~/img/icons/description.svg";
import IconChevronRight from "~/img/icons/chevron_right.svg";

export default function CTAs(props: { wrapper: ProgramWrapper }) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { sendClick }] = useEventsContext();
  const settings = useDrupalSettingsContext();

  return (
    <>
      <div class="ctas">
        <ul>
          <Show when={props.wrapper.program.field_leaflet?.uri?.url}>
            <li>
              <Show
                when={settings.form_leaflet_bypass}
                fallback={
                  <button
                    type="button"
                    class="btn btn-icon"
                    aria-label="Télécharger la brochure"
                    data-test="cta-leaflet"
                    onClick={(e) => {
                      sendShowEvent(`leaflet`, e, {
                        nid: props.wrapper.program.drupal_internal__nid,
                      });
                      openModalForm!("leaflet", { wrapper: props.wrapper });
                      setModalFormHeadline!("Télécharger la brochure");
                    }}
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconDescription />
                    </i>
                  </button>
                }
              >
                <a
                  href={props.wrapper.program.field_leaflet?.uri?.url}
                  class="btn btn-icon"
                  aria-label="Télécharger la brochure"
                  data-test="btn-leaflet"
                  target="_blank"
                  onClick={() => {
                    sendClick(`btn-leaflet`, "programs");
                  }}
                  rel="noindex nofollow"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconDescription />
                  </i>
                </a>
              </Show>
              <span class="label">Brochure</span>
            </li>
          </Show>
          <li>
            <button
              type="button"
              class="btn btn-icon"
              aria-label="Être appelé"
              data-test="cta-callback"
              onClick={(e) => {
                sendShowEvent(`callback`, e, {
                  nid: props.wrapper.program.drupal_internal__nid,
                });
                openModalForm!("callback", { wrapper: props.wrapper });
                setModalFormHeadline!("Être appelé");
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconCall />
              </i>
            </button>
            <span class="label">Être appelé</span>
          </li>
          <li>
            <a
              href={urlRs("programs", props.wrapper.program.path.alias)}
              class="btn btn-icon"
              title="En savoir +"
              data-test="more"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconChevronRight />
              </i>
            </a>
            <span class="label">En savoir +</span>
          </li>
        </ul>
      </div>
    </>
  );
}
