import { createMemo, Show } from "solid-js";

import type { ParagraphPush } from "~/types/drupal_jsonapi";

export default function CardPush(props: { pushTop: ParagraphPush }) {
  const remainingDaysCount = createMemo(() => {
    if (!props.pushTop?.field_countdown_stop) {
      return 0;
    }

    const now = new Date();
    const date = new Date(props.pushTop?.field_countdown_stop);

    if (!date) {
      return 0;
    }

    const diff = date.getTime() - now.getTime();
    const days = Math.floor(diff / (60 * 60 * 24 * 1000));
    return days > 0 ? days : 0;
  });

  return (
    <>
      <Show when={props.pushTop?.field_disp}>
        <span class="push">
          <Show when={props.pushTop.field_title}>
            {props.pushTop.field_title?.slice(0, 40)}
          </Show>
          <Show
            when={
              props.pushTop.field_countdown_disp && remainingDaysCount() > 0
            }
          >
            {" "}
            J-{remainingDaysCount()}
          </Show>
        </span>
      </Show>
    </>
  );
}
