export default function CardOffer() {
  return (
    <>
      <span class="offer">
        Offre
        <br />
        exclusive
      </span>
    </>
  );
}
